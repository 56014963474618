import React from "react"
import { SEO } from "./seo"
import { Footer } from "./footer"
import { useCookieConsent } from "./use-cookie-consent"

export const Layout = ({ children, data, white = false }) => {
  useCookieConsent()

  return (
    <div className={`${white ? "" : "bg-back"}`}>
      <SEO data={data} />
      {children}
      <Footer data={data} />
    </div>
  )
}
