import React from "react"
import { Helmet } from "react-helmet"
import favicon from "../images/logo2.png"

export const SEO = ({ data }) => {
  return (
    <Helmet htmlAttributes={{ lang: process.env.CONTENTFUL_LOCALE }}>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{data.seo.data.title}</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Poppins:ital,wght@0,400;0,700;1,300;1,700&display=swap"
        rel="stylesheet"
      />
      <meta name="description" content={data.seo.data.description} />
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  )
}
