import logo from "../images/logo.svg"
import React from "react"

export const Footer = ({ data }) => (
  <div className="py-12 md:pt-24 bg-back">
    <div className="flex justify-center">
      <div className="w-32 md:w-auto">
        <img src={logo} alt="Logo" />
      </div>
    </div>
    <div className="flex flex-col justify-center mt-12 text-center text-white opacity-40 md:flex-row md:gap-x-12 gap-y-4 md:mt-6">
      <div className="text-sm font-title">
        <a href={data.legal.data.terms.url}>{data.legal.data.terms.title}</a>
      </div>
      <div className="text-sm font-title">
        <a href={data.legal.data.privacy.url}>
          {data.legal.data.privacy.title}
        </a>
      </div>
    </div>
  </div>
)
