import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useEffect, useState } from "react"
import { useLocation } from "@reach/router"

export const useCookieConsent = () => {
  const [isCookieAllowed, setIsCookieAllowed] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const interval = setInterval(() => {
      if (!window.cookiehub || !window.cookiehub.hasConsented) return

      if (typeof window.cookiehub.hasConsented !== "function") return

      if (!window.cookiehub.hasConsented("analytics")) return

      setIsCookieAllowed(true)
      clearInterval(interval)
    }, 1000)
  }, [])

  useEffect(() => {
    if (isCookieAllowed && !localStorage.getItem("isTrackingAnalytics")) {
      initializeAndTrack(location)
      localStorage.setItem("isTrackingAnalytics", "true")
    }
  }, [isCookieAllowed, location])
}
